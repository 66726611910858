define("spaces/components/editor/list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="manage-editors-list" ...attributes>
    <Input::Options::Frame class="text-list" @title={{capitalize (t "option-panel.text-list")}}>
      <Input::StringList @value={{this.textList}} @onChange={{fn this.change "_textList"}} />
    </Input::Options::Frame>
  
    <Input::Options::Frame class='text-style' @title={{t 'option-panel.text-style'}}>
      <Editor::Properties::Text @value={{this.textStyle}} @deviceSize={{@deviceSize}}
        @onDeviceSizeChange={{@onDeviceSizeChange}} @onChange={{fn this.change "_textStyle"}}
        @enableTextValue={{false}} />
    </Input::Options::Frame>
  
    <Input::Options::Frame class='icon-style' @title={{t 'option-panel.icon-style'}}>
      <Input::Options::Panel @config={{this.iconStyleConfig}} @deviceSize={{@deviceSize}} @value={{this.iconStyle}}
        @onChange={{fn this.change "_iconStyle"}} />
    </Input::Options::Frame>
  </div>
  */
  {
    "id": "TLPT/kJN",
    "block": "[[[11,0],[24,0,\"manage-editors-list\"],[17,1],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"text-list\"]],[[\"@title\"],[[28,[37,2],[[28,[37,3],[\"option-panel.text-list\"],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@onChange\"],[[30,0,[\"textList\"]],[28,[37,5],[[30,0,[\"change\"]],\"_textList\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"text-style\"]],[[\"@title\"],[[28,[37,3],[\"option-panel.text-style\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@value\",\"@deviceSize\",\"@onDeviceSizeChange\",\"@onChange\",\"@enableTextValue\"],[[30,0,[\"textStyle\"]],[30,2],[30,3],[28,[37,5],[[30,0,[\"change\"]],\"_textStyle\"],null],false]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"icon-style\"]],[[\"@title\"],[[28,[37,3],[\"option-panel.icon-style\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],null,[[\"@config\",\"@deviceSize\",\"@value\",\"@onChange\"],[[30,0,[\"iconStyleConfig\"]],[30,2],[30,0,[\"iconStyle\"]],[28,[37,5],[[30,0,[\"change\"]],\"_iconStyle\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@deviceSize\",\"@onDeviceSizeChange\"],false,[\"div\",\"input/options/frame\",\"capitalize\",\"t\",\"input/string-list\",\"fn\",\"editor/properties/text\",\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let ManageEditorsListComponent = _exports.default = (_class = class ManageEditorsListComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "_textStyle", _descriptor, this);
      _initializerDefineProperty(this, "_iconStyle", _descriptor2, this);
      _initializerDefineProperty(this, "_textList", _descriptor3, this);
      _defineProperty(this, "iconStyleConfig", [{
        name: "name",
        options: ["circle-check", "check", "circle", "circle-dot", "plus", "minus", "hashtag", "asterisk", "check-double", "square-check", "arrow-right", "circle-arrow-right", "circle-right"]
      }, {
        name: "size",
        options: ["1", "2", "3", "4"]
      }, {
        name: "color",
        type: "color"
      }]);
    }
    get textList() {
      if (this._textList) {
        return this._textList;
      }
      return this.args.value?.data?.textList;
    }
    get textStyle() {
      if (this._textStyle) {
        return this._textStyle;
      }
      return this.args.value?.data?.textStyle;
    }
    get iconStyle() {
      if (this._iconStyle) {
        return this._iconStyle;
      }
      return this.args.value?.data?.iconStyle;
    }
    change(name, value) {
      this[name] = value;
      return this.triggerChange();
    }
    triggerChange() {
      return this.args.onChange({
        textList: this.textList,
        textStyle: this.textStyle,
        iconStyle: this.iconStyle
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "_textStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_iconStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_textList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ManageEditorsListComponent);
});