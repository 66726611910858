define("spaces/components/editor/source-stats", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='manage-editors-stats'>
    <Input::Options::Frame @title={{t 'option-panel.source' }} ...attributes>
      <div class='mb-3'>
        <div class='form-floating'>
          <Input::Select class='stats-name' id='{{this.elementId}}-stats-name' @list={{this.statsList}}
            @value={{@value.id}} @onChange={{this.sourceChanged}} />
          <label for='{{this.elementId}}-stats-name'>{{t 'option-panel.stats'}}</label>
        </div>
      </div>
    </Input::Options::Frame>
  </div>
  */
  {
    "id": "+FeoRLO1",
    "block": "[[[10,0],[14,0,\"manage-editors-stats\"],[12],[1,\"\\n  \"],[8,[39,1],[[17,1]],[[\"@title\"],[[28,[37,2],[\"option-panel.source\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"form-floating\"],[12],[1,\"\\n        \"],[8,[39,3],[[24,0,\"stats-name\"],[16,1,[29,[[30,0,[\"elementId\"]],\"-stats-name\"]]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,0,[\"statsList\"]],[30,2,[\"id\"]],[30,0,[\"sourceChanged\"]]]],null],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[29,[[30,0,[\"elementId\"]],\"-stats-name\"]]],[12],[1,[28,[35,2],[\"option-panel.stats\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@value\"],false,[\"div\",\"input/options/frame\",\"t\",\"input/select\",\"label\"]]",
    "moduleName": "spaces/components/editor/source-stats.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let EditorSourceStatsComponent = _exports.default = (_class = class EditorSourceStatsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "statsList", [{
        id: "published-campaigns",
        name: "published survey"
      }, {
        id: "campaign-answer-contributors",
        name: "survey answer contributors"
      }, {
        id: "campaign-contributions",
        name: "survey contributions"
      }]);
    }
    sourceChanged(value) {
      this.args.onChange({
        model: "stat",
        id: value.id
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "sourceChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sourceChanged"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorSourceStatsComponent);
});