define("spaces-view/components/state-filters/calendar-attribute", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "core/lib/page-state", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _tracking, _pageState, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="state-filters-attribute"
    {{did-insert this.setup}}
    {{did-update this.setup @state}}
    ...attributes
  >
    {{#if this.hasSearch}}
      <Filters::SearchOptions
        @niceValue={{this.niceValue}}
        @title={{this.title}}
        @placeholder={{this.placeholder}}
        @onReset={{this.reset}}
        @onSearch={{this.search}}
      >
        {{#if this.results}}
          <hr class="m-0" />
          <div class="list-group list-group-flush">
            {{#each this.results as |value|}}
              <button
                type="button"
                class="list-group-item list-group-item-action"
                {{on "click" (fn this.select value)}}
              >
                {{value}}
              </button>
            {{/each}}
          </div>
        {{/if}}
      </Filters::SearchOptions>
    {{else}}
      <Filters::Options
        @value={{this.niceValue}}
        @title={{this.title}}
        @onChange={{this.reset}}
        class="limit-height"
      >
        <div class="list-group list-group-flush">
          {{#each this.results as |value|}}
            <button
              type="button"
              class="list-group-item list-group-item-action"
              {{on "click" (fn this.select value)}}
            >
              {{value}}
            </button>
          {{/each}}
        </div>
      </Filters::Options>
    {{/if}}
  </div>
  */
  {
    "id": "USJrf+9v",
    "block": "[[[11,0],[24,0,\"state-filters-attribute\"],[17,1],[4,[38,1],[[30,0,[\"setup\"]]],null],[4,[38,2],[[30,0,[\"setup\"]],[30,2]],null],[12],[1,\"\\n\"],[41,[30,0,[\"hasSearch\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@niceValue\",\"@title\",\"@placeholder\",\"@onReset\",\"@onSearch\"],[[30,0,[\"niceValue\"]],[30,0,[\"title\"]],[30,0,[\"placeholder\"]],[30,0,[\"reset\"]],[30,0,[\"search\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"results\"]],[[[1,\"        \"],[10,\"hr\"],[14,0,\"m-0\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"list-group list-group-flush\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"            \"],[11,\"button\"],[24,0,\"list-group-item list-group-item-action\"],[24,4,\"button\"],[4,[38,9],[\"click\",[28,[37,10],[[30,0,[\"select\"]],[30,3]],null]],null],[12],[1,\"\\n              \"],[1,[30,3]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,11],[[24,0,\"limit-height\"]],[[\"@value\",\"@title\",\"@onChange\"],[[30,0,[\"niceValue\"]],[30,0,[\"title\"]],[30,0,[\"reset\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"list-group list-group-flush\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"results\"]]],null]],null],null,[[[1,\"          \"],[11,\"button\"],[24,0,\"list-group-item list-group-item-action\"],[24,4,\"button\"],[4,[38,9],[\"click\",[28,[37,10],[[30,0,[\"select\"]],[30,4]],null]],null],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@state\",\"value\",\"value\"],false,[\"div\",\"did-insert\",\"did-update\",\"if\",\"filters/search-options\",\"hr\",\"each\",\"-track-array\",\"button\",\"on\",\"fn\",\"filters/options\"]]",
    "moduleName": "spaces-view/components/state-filters/calendar-attribute.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let StateFiltersCalendarAttributeComponent = _exports.default = (_class = class StateFiltersCalendarAttributeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "clickOutside", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "results", _descriptor3, this);
      _initializerDefineProperty(this, "parentRecord", _descriptor4, this);
    }
    get teamId() {
      return this.args?.model?.team?.get?.('id');
    }
    get title() {
      return this.args.options?.label;
    }
    get placeholder() {
      return this.args.options?.['placeholder-text'];
    }
    get hasSearch() {
      return this.args.options?.['with-search'];
    }
    get state() {
      return (0, _pageState.fromPageState)(this.args.state);
    }
    get attrState() {
      const at = this.state.at ?? '';
      return (0, _pageState.fromPageState)(at.replace(/\|/gi, '@'));
    }
    get attributeName() {
      return this.args.options?.['attribute-name'];
    }
    get niceValue() {
      return this.attrState[this.attributeName];
    }
    search(term) {
      (0, _runloop.cancel)(this.queryTimer);
      if (!term || term.length < 3) {
        return;
      }
      this.queryTimer = (0, _runloop.later)(async () => {
        try {
          this.results = await this.parentRecord?.getAttributeValues(this.attributeName, term, 10);
        } catch (err) {
          this.results = [];
        }
      }, 500);
    }
    select(value) {
      const at = (0, _pageState.toPageState)({
        ...this.attrState,
        [this.attributeName]: value
      }).replace(/@/gi, '|');
      const newState = (0, _pageState.toPageState)({
        ...this.state,
        at
      });
      this.args.onChangeState?.(newState);
    }
    reset() {
      this.select();
    }
    async setup() {
      const calendarId = this.args.options?.calendar?.id || this.args.options?.calendar;
      if (!calendarId) {
        return;
      }
      try {
        this.parentRecord = await this.store.findRecord('calendar', calendarId);
        this.results = await this.parentRecord.getAttributeValues(this.attributeName, '', this.hasSearch ? 5 : 50);
      } catch (err) {
        this.results = [];
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "clickOutside", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "results", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "parentRecord", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "search", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersCalendarAttributeComponent);
});