define("spaces/components/highlight-frame", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="highlight-frame {{concat @value.selectionType "-frame"}}" style={{@value.style}}>
  </div>
  
  {{!-- template-lint-disable no-invalid-interactive --}}
  <div style={{@value.titleStyle}} {{did-insert this.setupTitle}} {{on "click" this.select}} {{on "mouseover" this.activate}} class="highlight-title title-{{@value.normalizedName}} {{if this.showName "has-name" "no-name"}} {{concat @value.selectionType "-highlight-title"}}">
    {{#if @value.gid}}
      <div class="global"><FaIcon @icon="globe" /></div>
    {{/if}}
  
    {{#if @value.showName}}
      <div class="name">{{@value.name}}</div>
    {{/if}}
  
    {{#if this.showTextType}}
      <div class="type">{{@value.type}}</div>
    {{/if}}
  
    {{#if this.iconType}}
      <div class="icon-type">
        <FaIcon @icon={{this.iconType}} />
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "7T3tYzsD",
    "block": "[[[10,0],[15,0,[29,[\"highlight-frame \",[28,[37,1],[[30,1,[\"selectionType\"]],\"-frame\"],null]]]],[15,5,[30,1,[\"style\"]]],[12],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[11,0],[16,5,[30,1,[\"titleStyle\"]]],[16,0,[29,[\"highlight-title title-\",[30,1,[\"normalizedName\"]],\" \",[52,[30,0,[\"showName\"]],\"has-name\",\"no-name\"],\" \",[28,[37,1],[[30,1,[\"selectionType\"]],\"-highlight-title\"],null]]]],[4,[38,3],[[30,0,[\"setupTitle\"]]],null],[4,[38,4],[\"click\",[30,0,[\"select\"]]],null],[4,[38,4],[\"mouseover\",[30,0,[\"activate\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"gid\"]],[[[1,\"    \"],[10,0],[14,0,\"global\"],[12],[8,[39,5],null,[[\"@icon\"],[\"globe\"]],null],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"showName\"]],[[[1,\"    \"],[10,0],[14,0,\"name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showTextType\"]],[[[1,\"    \"],[10,0],[14,0,\"type\"],[12],[1,[30,1,[\"type\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"iconType\"]],[[[1,\"    \"],[10,0],[14,0,\"icon-type\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@icon\"],[[30,0,[\"iconType\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@value\"],false,[\"div\",\"concat\",\"if\",\"did-insert\",\"on\",\"fa-icon\"]]",
    "moduleName": "spaces/components/highlight-frame.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let HighlightFrameComponent = _exports.default = (_class = class HighlightFrameComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "icons", {
        slot: "plug",
        "data source": "database"
      });
    }
    get showTextType() {
      if (!this.args.value.type) {
        return false;
      }
      if (this.icons[this.args.value.type]) {
        return false;
      }
      return this.args.value.name != this.args.value.type;
    }
    get iconType() {
      if (!this.args.value.type) {
        return false;
      }
      return this.icons[this.args.value.type];
    }
    select() {
      return this.args?.onSelect?.(this.args.value);
    }
    setupTitle(element) {
      const bbox = element.getBoundingClientRect();
      this.args.value.titleWidth = Math.ceil(bbox.width);
    }
    activate() {
      this.args.value.isPrimary = true;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTitle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "activate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HighlightFrameComponent);
});