define("spaces-view/components/state-filters/date", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "core/lib/page-state", "luxon", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _pageState, _luxon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="state-filters-date" ...attributes>
    <Filters::Options
      @value={{this.value}}
      @title={{this.title}}
      @onChange={{this.change}}
    >
      <DaySelector @value={{this.dateValue}} @allowClear={{true}} @onChange={{this.change}} />
    </Filters::Options>
  </div>
  */
  {
    "id": "g3uNyLnK",
    "block": "[[[11,0],[24,0,\"state-filters-date\"],[17,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@title\",\"@onChange\"],[[30,0,[\"value\"]],[30,0,[\"title\"]],[30,0,[\"change\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@allowClear\",\"@onChange\"],[[30,0,[\"dateValue\"]],true,[30,0,[\"change\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"div\",\"filters/options\",\"day-selector\"]]",
    "moduleName": "spaces-view/components/state-filters/date.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let StateFiltersDateComponent = _exports.default = (_class = class StateFiltersDateComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "clickOutside", _descriptor2, this);
    }
    get title() {
      return this.args.options?.label ?? this.intl.t('date-label');
    }
    get dateValue() {
      if (!this.state.da) {
        return null;
      }
      return _luxon.DateTime.fromISO(this.state.da);
    }
    get value() {
      if (!this.state.da) {
        return '';
      }
      return this.dateValue.toLocaleString();
    }
    get state() {
      return (0, _pageState.fromPageState)(this.args.state);
    }
    change(value) {
      const newState = (0, _pageState.toPageState)({
        ...this.state,
        da: value?.startOf?.('day').plus({
          hours: 12
        }).toISO?.()
      });
      this.args.onChangeState?.(newState);
      this.clickOutside.unsubscribe();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clickOutside", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersDateComponent);
});