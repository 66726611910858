define("spaces-view/components/picture-list-full-frame", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="d-none" {{did-insert this.setup}}></div>
  
  {{#if this.galleryTarget}}
    {{#in-element this.galleryTarget}}
      <div class="page-col-gallery page-col-gallery-modal d-flex flex-column
        {{if this.isGalleryPresentable 'presentable'}}
        {{if this.isGalleryPresenting 'presenting'}}" {{on "transitionend" this.checkHideGallery}}>
        <div class="gallery-header p-2 mb-2 d-flex">
          <button type="button" class="btn btn-light btn-close-modal" {{on "click" this.prepareHideGallery}}>
            <FaIcon @icon="times" />
          </button>
          <div class="title p-2 flex-fill">
            {{@title}}
          </div>
        </div>
  
        <div class="flex-fill scrollable">
          <div class="large-picture-list container d-flex align-items-center flex-wrap justify-content-center">
            {{#each @value as |picture|}}
              <PictureWithOptions class="page-col-picture cursor-pointer" @name={{picture.name}}
                @meta={{picture.meta}} @src={{picture.picture}} @hash={{picture.hash}}
                @options={{this.largePictureValues}} @onClick={{fn this.select picture}} />
            {{/each}}
          </div>
        </div>
      </div>
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "t6vJzGYU",
    "block": "[[[11,0],[24,0,\"d-none\"],[4,[38,1],[[30,0,[\"setup\"]]],null],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"galleryTarget\"]],[[[40,[[[1,\"    \"],[11,0],[16,0,[29,[\"page-col-gallery page-col-gallery-modal d-flex flex-column\\n      \",[52,[30,0,[\"isGalleryPresentable\"]],\"presentable\"],\"\\n      \",[52,[30,0,[\"isGalleryPresenting\"]],\"presenting\"]]]],[4,[38,5],[\"transitionend\",[30,0,[\"checkHideGallery\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"gallery-header p-2 mb-2 d-flex\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-light btn-close-modal\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"prepareHideGallery\"]]],null],[12],[1,\"\\n          \"],[8,[39,7],null,[[\"@icon\"],[\"times\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"title p-2 flex-fill\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"flex-fill scrollable\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"large-picture-list container d-flex align-items-center flex-wrap justify-content-center\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[30,2]],null]],null],null,[[[1,\"            \"],[8,[39,10],[[24,0,\"page-col-picture cursor-pointer\"]],[[\"@name\",\"@meta\",\"@src\",\"@hash\",\"@options\",\"@onClick\"],[[30,3,[\"name\"]],[30,3,[\"meta\"]],[30,3,[\"picture\"]],[30,3,[\"hash\"]],[30,0,[\"largePictureValues\"]],[28,[37,11],[[30,0,[\"select\"]],[30,3]],null]]],null],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,4],[[30,0,[\"galleryTarget\"]]],null]]],[]],null]],[\"@title\",\"@value\",\"picture\"],false,[\"div\",\"did-insert\",\"if\",\"in-element\",\"-in-el-null\",\"on\",\"button\",\"fa-icon\",\"each\",\"-track-array\",\"picture-with-options\",\"fn\"]]",
    "moduleName": "spaces-view/components/picture-list-full-frame.hbs",
    "isStrictMode": false
  });
  let PictureListFullFrameComponent = _exports.default = (_class = class PictureListFullFrameComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fullscreen", _descriptor, this);
      _initializerDefineProperty(this, "isGalleryPresentable", _descriptor2, this);
      _initializerDefineProperty(this, "isGalleryPresenting", _descriptor3, this);
      _initializerDefineProperty(this, "galleryTarget", _descriptor4, this);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.fullscreen.unfreezeBody();
    }
    setup() {
      this.galleryTarget = document.querySelector('#gallery-target');
      (0, _runloop.later)(() => {
        this.fullscreen.freezeBody();
        this.isGalleryPresentable = true;
      }, 1);
    }
    prepareHideGallery() {
      this.isGalleryPresentable = false;
      (0, _runloop.later)(() => {
        const modal = this.galleryTarget.querySelector('.page-col-gallery-modal');
        if (!modal) {
          return;
        }
        const style = getComputedStyle?.(modal);
        if (style.opacity == 0) {
          this.checkHideGallery();
        }
      }, 10);
    }
    checkHideGallery() {
      if (!this.isGalleryPresentable) {
        return this.hideGallery();
      }
      this.isGalleryPresenting = true;
    }
    hideGallery() {
      this.isGalleryVisible = false;
      this.isGalleryPresentable = false;
      this.isGalleryPresenting = false;
      this.args.onHide?.();
    }
    select(picture) {
      this.args.onSelect?.(picture);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fullscreen", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isGalleryPresentable", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isGalleryPresenting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "galleryTarget", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prepareHideGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "prepareHideGallery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkHideGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkHideGallery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideGallery", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideGallery"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PictureListFullFrameComponent);
});