define("map/components/map-selectable", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "ol/proj", "core/lib/geoJson", "@ember/object", "map/lib/map/feature-selection", "@ember/template-factory"], function (_exports, _component, _component2, _service, _proj, _geoJson, _object, _featureSelection, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @id}}
    <MapInteractive @userLocation={{this.position.center}} @followUserLocation={{this.userInExtent}} class="map-selectable" as | map |>
      <div class="d-none" {{did-insert (fn this.setupExtent map)}}></div>
      <Map::Layer::VectorTiles
        @map={{map}}
        @style={{this.mapStyles.styleFunction}}
        @onSelect={{this.handleSelection}}
        @selectedId={{this.selectedFeature}}
        @bearer={{this.tiles.bearer}}
        @url={{this.tilesUrl}}
      />
    </MapInteractive>
  {{/if}}
  */
  {
    "id": "afIGIH3d",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[24,0,\"map-selectable\"]],[[\"@userLocation\",\"@followUserLocation\"],[[30,0,[\"position\",\"center\"]],[30,0,[\"userInExtent\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[11,0],[24,0,\"d-none\"],[4,[38,3],[[28,[37,4],[[30,0,[\"setupExtent\"]],[30,2]],null]],null],[12],[13],[1,\"\\n    \"],[8,[39,5],null,[[\"@map\",\"@style\",\"@onSelect\",\"@selectedId\",\"@bearer\",\"@url\"],[[30,2],[30,0,[\"mapStyles\",\"styleFunction\"]],[30,0,[\"handleSelection\"]],[30,0,[\"selectedFeature\"]],[30,0,[\"tiles\",\"bearer\"]],[30,0,[\"tilesUrl\"]]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[]],null]],[\"@id\",\"map\"],false,[\"if\",\"map-interactive\",\"div\",\"did-insert\",\"fn\",\"map/layer/vector-tiles\"]]",
    "moduleName": "map/components/map-selectable.hbs",
    "isStrictMode": false
  });
  let MapSelectable = _exports.default = (_class = class MapSelectable extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "tiles", _descriptor, this);
      _initializerDefineProperty(this, "mapStyles", _descriptor2, this);
      _initializerDefineProperty(this, "position", _descriptor3, this);
      _defineProperty(this, "selection", new _featureSelection.FeatureSelection());
    }
    get tilesUrl() {
      return `${this.tiles.url}/{z}/{x}/{y}?format=vt&map=${this.args.id}`;
    }
    get extent() {
      return new _geoJson.GeoJson(this.args.extent);
    }
    get userInExtent() {
      if (!this.position.hasPosition) {
        return false;
      }
      const extent = this.extent.toOlFeature().getGeometry();
      const point = new _geoJson.GeoJson(this.position.center).toOlFeature().getGeometry().getCoordinates();
      return extent.intersectsCoordinate(point);
    }
    setupExtent(map) {
      if (this.userInExtent) {
        return;
      }
      const extentM = this.extent.toOlFeature().getGeometry().getExtent();
      const extent = (0, _proj.transformExtent)(extentM, 'EPSG:4326', 'EPSG:3857');
      map.getView().fit(extent);
    }
    handleSelection() {
      this.selection.update(...arguments);
      if (this.selection.group?.getProperties) {
        let ids = this.selection.group.getProperties()["idList"];
        if (typeof ids != "string") {
          ids = "";
        }
        return this.args.onSelect(ids.split(","));
      }
      return this.args.onSelect([this.selection.featureId]);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "tiles", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mapStyles", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "position", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupExtent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupExtent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelection"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapSelectable);
});