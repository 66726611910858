define("map/components/map/button-localize", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="map-controls" ...attributes>
    <button
      type="button"
      class="btn btn-sm
        {{if this.position.errorMessage 'btn-danger' 'btn-success'}}
        btn-localize"
      data-placement="left"
      {{on "click" this.position.watchPosition}}
      title="Localize"
    >
      {{#if this.position.isWaiting}}
        <FaIcon @icon="sync" @spin="true" />
      {{else}}
        <FaIcon @icon="crosshairs" />
      {{/if}}
    </button>
  </div>
  */
  {
    "id": "fJcxW+dC",
    "block": "[[[11,0],[24,0,\"map-controls\"],[17,1],[12],[1,\"\\n  \"],[11,\"button\"],[16,0,[29,[\"btn btn-sm\\n      \",[52,[30,0,[\"position\",\"errorMessage\"]],\"btn-danger\",\"btn-success\"],\"\\n      btn-localize\"]]],[24,\"data-placement\",\"left\"],[24,\"title\",\"Localize\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"position\",\"watchPosition\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"position\",\"isWaiting\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@icon\",\"@spin\"],[\"sync\",\"true\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@icon\"],[\"crosshairs\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"div\",\"button\",\"if\",\"on\",\"fa-icon\"]]",
    "moduleName": "map/components/map/button-localize.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let MapButtonLocalizeComponent = _exports.default = (_class = class MapButtonLocalizeComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "position", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "position", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapButtonLocalizeComponent);
});